import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

import InstagramLogo from "../../media/svg/instagram.svg";
import FacebookLogo from "../../media/svg/facebook.svg";
import YoutubeLogo from "../../media/svg/youtube.svg";
import TwitterLogo from "../../media/svg/twitter.svg";
import { Link } from "gatsby";
import { FooterState } from "./footer-state";

export default function Footer() {
  const [status, setStatus] = useState(FooterState.Idle);

  function handleSubmit(event) {
    event.preventDefault();
    setStatus(FooterState.Processing);

    const mailingListForm = event.target;
    const formData = new FormData(mailingListForm);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then((response) => {
        if (response.ok) {
          mailingListForm.reset();

          setStatus(FooterState.Success);
        } else {
          setStatus(FooterState.Error);
        }
      })
      .catch(() => {
        setStatus(FooterState.Error);
      });
  }

  return (
    <footer>
      <div className="flex flex-col justify-center items-center bg-slate-200 dark:bg-zinc-950 transition-colors duration-300 ease-out relative overflow-hidden">
        <StaticImage
          className="z-[0] opacity-[0.05] absolute top-0 left-0 h-full w-screen pointer-events-none"
          objectFit="cover"
          src="../../media/gritty.jpg"
          alt="Background Texture"
        />
        <StaticImage
          className="pt-8 pb-6 w-36 sm:w-56 hover:opacity-50 invert-0 dark:invert dark:hover:opacity-50 transition duration-500 ease-out"
          objectFit="contain"
          placeholder="none"
          src="../../media/text_logo.png"
          alt="FreeSpvce Text Logo"
        />
        <div className="w-full">
          <form
            className="text-center flex justify-center items-center flex-col"
            id="mailing-list"
            name="Mailing List"
            method="POST"
            netlify-honeypot="special-field"
            data-netlify="true"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="Mailing List" />
            <label
              className="text-slate-900 dark:text-slate-200 transition-colors duration-300 ease-out text-lg font-semibold"
              htmlFor="email"
            >
              Newsletter
            </label>
            <div className="flex items-center mt-1 mb-4">
              <input
                className="w-28 min-[300px]:w-40 md:w-60 p-1 h-9 border border-slate-900 dark:border-zinc-950 hoverFocus:border-primary bg-slate-200 text-slate-900 placeholder-slate-600 dark:bg-zinc-400 dark:placeholder-zinc-600 transition-colors duration-300 ease-out outline-0 z-10"
                type="email"
                name="email-address"
                placeholder="Email"
                id="email-address"
                autoComplete="email"
                required
              ></input>
              <div className="hidden">
                <label htmlFor="special-field">
                  Don't fill this out if you're human:
                </label>
                <input name="special-field" />
              </div>
              <button
                disabled={
                  status === FooterState.Processing ||
                  status === FooterState.Success
                }
                className="bg-slate-200 hoverFocus:bg-slate-900 dark:bg-zinc-400 dark:hoverFocus:bg-zinc-950 text-sm transition-colors duration-500 ease-out py-1 px-2 h-9 border-slate-900 text-slate-900 hoverFocus:text-slate-200 dark:border-zinc-950 dark:hoverFocus:text-slate-50 z-10"
                style={{
                  borderTop: "1px solid",
                  borderRight: "1px solid",
                  borderBottom: "1px solid",
                }}
                type="submit"
              >
                {status === FooterState.Success
                  ? "Success"
                  : status === FooterState.Error
                  ? "Error"
                  : status === FooterState.Processing
                  ? "Processing"
                  : "Subscribe"}
              </button>
            </div>
          </form>
        </div>
        <div className="w-full flex justify-center items-center flex-wrap gap-x-4 gap-y-2 px-2 text-slate-900 dark:text-slate-200 transition-colors duration-300 ease-out text-sm mt-4 mb-6">
          <Link
            to="/about"
            className="hoverFocus:text-slate-500 dark:hoverFocus:text-slate-400 transition-colors"
          >
            About
          </Link>
          <Link
            to="/contact"
            className="hoverFocus:text-slate-500 dark:hoverFocus:text-slate-400 transition-colors"
          >
            Contact
          </Link>
          <Link
            to="/privacy-policy"
            className="hoverFocus:text-slate-500 dark:hoverFocus:text-slate-400 transition-colors"
          >
            Privacy Policy
          </Link>
          <Link
            to="/sitemap-0.xml"
            className="hoverFocus:text-slate-500 dark:hoverFocus:text-slate-400 transition-colors"
          >
            Sitemap
          </Link>
        </div>
      </div>
      <div
        className="relative"
        style={{ boxShadow: "0px 0px 25px 10px rgba(0,0,0,0.5)" }}
      >
        <StaticImage
          className="z-[0] opacity-[0.05] absolute top-0 pointer-events-none left-0 h-full w-screen"
          objectFit="cover"
          src="../../media/gritty.jpg"
          alt="Background Texture"
        />
        <div className="h-12 bg-slate-500 dark:bg-zinc-800 transition-colors duration-300 ease-out grid place-items-center">
          <div className="grid grid-cols-4 gap-6">
            <a
              className="group"
              href="https://www.instagram.com/FreeSpvce/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <InstagramLogo
                height={24}
                width={24}
                className="fill-slate-100 group-hoverFocus:fill-slate-300 transition ease-out"
              />
              <span className="sr-only">Instagram: FreeSpvce</span>
            </a>
            <a
              className="group"
              href="https://www.facebook.com/FreeSpvce"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FacebookLogo
                height={24}
                width={24}
                className="fill-slate-100 group-hoverFocus:fill-slate-300 transition ease-out"
              />
              <span className="sr-only">Facebook: FreeSpvce</span>
            </a>
            <a
              className="group"
              href="https://www.youtube.com/channel/UCq3EVwQZWSeD28vRPLbz6tw"
              target="_blank"
              rel="noreferrer noopener"
            >
              <YoutubeLogo
                height={24}
                width={24}
                className="fill-slate-100 group-hoverFocus:fill-slate-300 transition ease-out"
              />
              <span className="sr-only">Youtube: FreeSpvce</span>
            </a>
            <a
              className="group"
              href="https://twitter.com/FreeSpvce"
              target="_blank"
              rel="noreferrer noopener"
            >
              <TwitterLogo
                height={24}
                width={24}
                className="fill-slate-100 group-hoverFocus:fill-slate-300 transition ease-out"
              />
              <span className="sr-only">Twitter: FreeSpvce</span>
            </a>
          </div>
        </div>
        <div className="relative bg-slate-500 dark:bg-zinc-800 transition-colors duration-300 ease-out">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-b border-slate-100"></div>
          </div>
          <div className="relative flex justify-center">
            <StaticImage
              className="z-[0] opacity-[0.05] absolute top-0 pointer-events-none left-0 h-full w-screen"
              objectFit="cover"
              src="../../media/gritty.jpg"
              alt="Background Texture"
            />
            <span className="bg-slate-500 dark:bg-zinc-800 transition-colors duration-300 ease-out px-4 text-sm text-slate-100">
              EST. 2017
            </span>
          </div>
        </div>
        <div className="bg-slate-500 dark:bg-zinc-800 transition-colors duration-300 ease-out text-center text-slate-100 w-full py-2">
          <p className="font-bold mb-1">© 2024 FreeSpvce LLC</p>
        </div>
      </div>
    </footer>
  );
}

export default function ScrollDelay(
  { routerProps: { location }, getSavedScrollPosition },
  pluginOptions
) {
  if (location.action === "PUSH") {
    window.scrollTo(0, 0);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.scrollTo(...(savedPosition || [0, 0]));
  }

  return false;
}

import React, { useContext } from "react";
import animatedLogo from "../../../media/animated_logo.webm";
import DropDownMenu from "./dropdown-menu";
import { useMemo } from "react";
import { Link } from "gatsby";

import Sun from "../../../media/svg/sun.svg";
import Moon from "../../../media/svg/moon.svg";
import Bars from "../../../media/svg/bars.svg";
import { MobileMenuOpenedContext } from "../../layout";
import { isMobileSafari, isSafari, isMobile } from "react-device-detect";
import { StaticImage } from "gatsby-plugin-image";
import { useDarkMode } from "../useDarkMode";

export default function Header({ className, backgroundBlur }) {
  const { menuOpened, setMenuOpened } = useContext(MobileMenuOpenedContext);

  const [lightMode] = useDarkMode(false, {
    classNameDark: "light",
    classNameLight: "dark",
    storageKey: "lightMode",
  });

  const combineClassNames = require("classnames");

  const headerMain = useMemo(
    () => (
      <>
        <div className="justify-self-start hidden lg:flex space-x-4 xl:space-x-8 mt-[10px] text-slate-900 fill-slate-900 dark:text-slate-50 dark:fill-slate-50 transition-colors duration-300 ease-out">
          <Link
            to="/"
            className="hover:text-primary transition-colors ease-out"
          >
            Home
          </Link>
          <DropDownMenu
            title="Shop"
            to="https://shop.freespvce.com"
            isExternalLink={true}
            childrenItems={[
              {
                path: "https://shop.freespvce.com/en-usd/collections/hoodies",
                name: "Hoodies",
              },
              {
                path: "https://shop.freespvce.com/en-usd/collections/t-shirts",
                name: "T-Shirts",
              },
              {
                path: "https://shop.freespvce.com/en-usd/collections/large-mousepad",
                name: "Large Mousepads",
              },
              {
                path: "https://shop.freespvce.com/en-usd/collections/hats",
                name: "Hats",
              },
              {
                path: "https://shop.freespvce.com/en-usd/collections/stickers",
                name: "Stickers",
              },
            ]}
          />
          <DropDownMenu
            title="Media"
            to="/media"
            childrenItems={[
              {
                path: "/media/livestreaming",
                name: "Livestreaming",
              },
              {
                path: "/media/production-work",
                name: "Production Work",
              },
              {
                path: "/media/music",
                name: "Music",
              },
              {
                path: "/media/videos",
                name: "Videos",
              },
            ]}
          />
        </div>
        <div className="lg:hidden flex justify-self-start mt-[10px] fill-slate-900 dark:fill-slate-50 transition-colors duration-300 ease-out">
          <button
            aria-label="Open Menu"
            onClick={() => {
              setMenuOpened(!menuOpened);
            }}
          >
            <Bars className="h-6 w-6" />
          </button>
        </div>
        <button
          onClick={(e) => onClickScrollToTop(e)}
          aria-label="Scroll Down"
          className="hover:opacity-50 transition duration-500 ease-out"
        >
          {isSafari || isMobileSafari ? (
            <StaticImage
              src="../../../media/black_logo.png"
              objectFit="cover"
              placeholder="none"
              className="dark:invert transition-colors duration-300 ease-out m-auto w-[70px] sm:w-[90px] mb-2 sm:mb-0 mt-2 sm:mt-4"
              alt="FreeSpvce Logo"
            />
          ) : (
            <video
              src={animatedLogo}
              type="video/webm"
              crossOrigin="anonymous"
              loop={true}
              muted={true}
              autoPlay={true}
              controls={false}
              className="mt-[-3px] dark:invert transition-colors duration-300 ease-out m-auto w-[100px] sm:w-[125px]"
              alt="FreeSpvce Logo"
            />
          )}
          <h1
            id="motto-text-header"
            className={combineClassNames(
              "font-bold text-slate-900 whitespace-nowrap text-sm sm:text-base dark:text-slate-50 transition-colors duration-300 ease-out",
              isSafari || isMobileSafari ? "" : "mt-[-8px] sm:mt-[-16px]"
            )}
          >
            BE YOU. BE FREE.
          </h1>
        </button>
        <div className="hidden lg:flex justify-self-end space-x-4 xl:space-x-8 mt-[10px] text-slate-900 fill-slate-900 dark:text-slate-50 dark:fill-slate-50 transition-all duration-300 ease-out">
          <Link
            to="/about"
            className="group inline-block align-baseline hover:text-primary transition-colors ease-out"
          >
            About
          </Link>
          <Link
            to="/contact"
            className="group inline-block align-baseline hover:text-primary transition-colors ease-out"
          >
            Contact
          </Link>
          <DarkModeButton />
        </div>
      </>
    ),
    [menuOpened, setMenuOpened, combineClassNames]
  );

  function onClickScrollToTop(e) {
    e && e.preventDefault();
    window.scrollTo(0, 0);
  }

  return (
    <div
      className={`w-[99%] m-[0.5%] grid grid-cols-3 fixed z-50 pb-[10px] place-items-center drop-shadow-md rounded-xl transition duration-300 ease-out px-5 sm:px-10 2xl:px-20 ${className}`}
      style={
        isMobile
          ? {
              backgroundColor: lightMode
                ? `rgb(255, 255, 255, 0.5)`
                : `rgb(0, 0, 0, 0.5)`,
            }
          : {
              backgroundColor: lightMode
                ? `rgb(255, 255, 255, 0.5)`
                : `rgb(0, 0, 0, 0.5)`,
              backdropFilter: `blur(${backgroundBlur}px)`,
            }
      }
    >
      {headerMain}
    </div>
  );
}

function DarkModeButton() {
  const [lightMode, action] = useDarkMode(false, {
    classNameDark: "light",
    classNameLight: "dark",
    storageKey: "lightMode",
  });

  return (
    <button
      className="animate-fadeIn fill-slate-900 dark:fill-slate-50 hover:fill-primary dark:hover:fill-primary"
      title="Switch Themes"
      aria-label="Switch Themes"
      onClick={action.toggle}
    >
      {lightMode ? (
        <Sun className="animate-fadeIn transition-all duration-300 ease-out h-5 w-5" />
      ) : (
        <Moon className="animate-fadeIn transition-all duration-300 ease-out h-5 w-5" />
      )}
    </button>
  );
}

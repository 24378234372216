import ScrollDelay from "./src/components/shouldUpdateScroll";
import CustomLayout from "./src/components/wrapPageElement";
import "./src/styles/global.css";

export const wrapPageElement = CustomLayout;
export const shouldUpdateScroll = ScrollDelay;

export const onRouteUpdate = ({ location }) => {
  if (location.pathname.toLowerCase().split("/")[1] === "shop") {
    window.location.replace("https://www.stackoverflow.com");
  }
};

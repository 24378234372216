import React, { createContext, useEffect, useState } from "react";

import "../styles/layout.css";
import Header from "./page/header/header";
import Footer from "./page/footer";
import Transition from "./transition";
import MobileMenu from "./page/mobile-menu";
import throttle from "lodash.throttle";
import { isMobile } from "react-device-detect";
import { DarkModeProvider } from "./page/useDarkMode";

export const MobileMenuOpenedContext = createContext(null);

export default function Layout(props) {
  const [menuOpened, setMenuOpened] = useState(false);

  const [headerState, setHeaderState] = useState({
    className: "",
    backgroundBlur: 5,
  });

  const headerStyle = props.pageContext.headerStyle ?? "none";

  function blurBackgroundOnScroll() {
    // Blur slowly climbs and is capped at 5. 6 is an constant number to intensify the blur
    const headerBlur = (window.scrollY / 1000 + 0) * 6;

    setHeaderState({
      className: "",
      backgroundBlur: headerBlur > 5 ? 5 : headerBlur,
    });
  }

  function fadeInOnScroll() {
    // Percentage of screen height that has been scrolled
    const scrollPercentage = window.scrollY / 1000;
    const scrollThreshold = 0.1;

    setHeaderState({
      className: `transition-all duration-300 ease-out ${
        scrollPercentage > scrollThreshold
          ? `visible opacity-1`
          : `invisible opacity-0`
      }`,
      backgroundBlur: 5,
    });
  }

  useEffect(() => {
    switch (headerStyle) {
      case "blurBackgroundOnScroll": {
        if (!isMobile) {
          setHeaderState({
            className: "",
            backgroundBlur: 0,
          });
          const throttled = throttle(blurBackgroundOnScroll, 250);
          window.addEventListener("scroll", throttled);
          return () => window.removeEventListener("scroll", throttled);
        } else {
          setHeaderState({
            className: "",
            backgroundBlur: 5,
          });
        }
        break;
      }
      case "fadeInOnScroll": {
        setHeaderState({
          className: "transition-all duration-300 ease-out invisible opacity-0",
          backgroundBlur: 5,
        });
        const throttled = throttle(fadeInOnScroll, 500);
        window.addEventListener("scroll", throttled);
        return () => window.removeEventListener("scroll", throttled);
      }
      default: {
        setHeaderState({
          className: "",
          backgroundBlur: 5,
        });
        break;
      }
    }
  }, [headerStyle]);

  return (
    <DarkModeProvider>
      <MobileMenuOpenedContext.Provider
        value={{ menuOpened: menuOpened, setMenuOpened: setMenuOpened }}
      >
        <Header {...headerState} pathName={props.location.pathname} />
        <Transition {...props}>
          <main className={`body ${props.location.pathname}`} id="main">
            {props.children}
          </main>
          <MobileMenu />
          <Footer />
        </Transition>
      </MobileMenuOpenedContext.Provider>
    </DarkModeProvider>
  );
}

import React, { Fragment, useContext, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";

import { StaticImage } from "gatsby-plugin-image";
import { MobileMenuOpenedContext } from "../layout";

import XMark from "../../media/svg/x-mark.svg";
import Sun from "../../media/svg/sun.svg";
import Moon from "../../media/svg/moon.svg";
import ChevronRight from "../../media/svg/chevron-right.svg";
import ArrowLeft from "../../media/svg/arrow-left.svg";

import { Link } from "gatsby";
import { useDarkMode } from "./useDarkMode";

export default function MobileMenu() {
  const { menuOpened, setMenuOpened } = useContext(MobileMenuOpenedContext);

  const [currentMenu, setCurrentMenu] = useState("main");

  const [lightMode] = useDarkMode(false, {
    classNameDark: "light",
    classNameLight: "dark",
    storageKey: "lightMode",
  });

  const combineClassNames = require("classnames");

  function closeMenu() {
    setMenuOpened(false);
    setCurrentMenu("main");
  }

  return (
    <Transition appear show={menuOpened} as={Fragment}>
      <Dialog as="div" className="relative z-[50]" onClose={() => closeMenu()}>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative h-screen w-screen">
                <StaticImage
                  className="z-[0] opacity-[0.05] absolute top-0 left-0 h-full w-full pointer-events-none"
                  objectFit="cover"
                  src="../../media/gritty.jpg"
                  alt="Background Texture"
                />
                <div
                  className={combineClassNames(
                    "h-full w-full",
                    lightMode
                      ? "bg-slate-200 fill-slate-900 text-slate-900"
                      : "bg-zinc-950 fill-slate-50 text-slate-50"
                  )}
                >
                  {currentMenu === "main" && (
                    <div className="h-full w-full flex flex-col justify-center space-y-4 items-center transition-all duration-300 ease-out">
                      <CloseMenuItem
                        mainMenuOpened={menuOpened}
                        closeMenu={closeMenu}
                      />
                      <MenuItem
                        index={1}
                        name="Home"
                        to="/"
                        isMenu={false}
                        mainMenuOpened={menuOpened}
                        setCurrentMenu={setCurrentMenu}
                        closeMenu={closeMenu}
                      />
                      <MenuItem
                        index={2}
                        name="Shop"
                        to="shop"
                        isMenu={true}
                        mainMenuOpened={menuOpened}
                        setCurrentMenu={setCurrentMenu}
                        closeMenu={closeMenu}
                      />
                      <MenuItem
                        index={3}
                        name="Media"
                        to="media"
                        isMenu={true}
                        mainMenuOpened={menuOpened}
                        setCurrentMenu={setCurrentMenu}
                        closeMenu={closeMenu}
                      />
                      <MenuItem
                        index={4}
                        name="About"
                        to="/about"
                        isMenu={false}
                        mainMenuOpened={menuOpened}
                        setCurrentMenu={setCurrentMenu}
                        closeMenu={closeMenu}
                      />
                      <MenuItem
                        index={5}
                        name="Contact"
                        to="/contact"
                        isMenu={false}
                        mainMenuOpened={menuOpened}
                        setCurrentMenu={setCurrentMenu}
                        closeMenu={closeMenu}
                      />
                      <DarkModeButton index={6} menuOpened={menuOpened} />
                    </div>
                  )}
                  {currentMenu === "shop" && (
                    <MenuPage
                      mainMenuOpened={menuOpened}
                      setCurrentMenu={setCurrentMenu}
                      menuItems={[
                        <MenuItem
                          index={1}
                          name="All"
                          isURL={true}
                          to="https://shop.freespvce.com"
                          isMenu={false}
                          mainMenuOpened={menuOpened}
                          setCurrentMenu={setCurrentMenu}
                          closeMenu={closeMenu}
                        />,
                        <MenuItem
                          index={2}
                          name={"Hoodies"}
                          isURL={true}
                          to="https://shop.freespvce.com/collections/hoodies"
                          isMenu={false}
                          mainMenuOpened={menuOpened}
                          setCurrentMenu={setCurrentMenu}
                          closeMenu={closeMenu}
                        />,
                        <MenuItem
                          index={3}
                          name={"T-Shirts"}
                          isURL={true}
                          to="https://shop.freespvce.com/collections/t-shirts"
                          isMenu={false}
                          mainMenuOpened={menuOpened}
                          setCurrentMenu={setCurrentMenu}
                          closeMenu={closeMenu}
                        />,
                        <MenuItem
                          index={4}
                          name={"Large Mousepads"}
                          isURL={true}
                          to="https://shop.freespvce.com/collections/large-mousepad"
                          isMenu={false}
                          mainMenuOpened={menuOpened}
                          setCurrentMenu={setCurrentMenu}
                          closeMenu={closeMenu}
                        />,
                        <MenuItem
                          index={5}
                          name={"Hats"}
                          isURL={true}
                          to="https://shop.freespvce.com/collections/hats"
                          isMenu={false}
                          mainMenuOpened={menuOpened}
                          setCurrentMenu={setCurrentMenu}
                          closeMenu={closeMenu}
                        />,
                        <MenuItem
                          index={6}
                          name={"Stickers"}
                          isURL={true}
                          to="https://shop.freespvce.com/collections/stickers"
                          isMenu={false}
                          mainMenuOpened={menuOpened}
                          setCurrentMenu={setCurrentMenu}
                          closeMenu={closeMenu}
                        />,
                      ]}
                    />
                  )}
                  {currentMenu === "media" && (
                    <MenuPage
                      mainMenuOpened={menuOpened}
                      setCurrentMenu={setCurrentMenu}
                      menuItems={[
                        <MenuItem
                          index={1}
                          name="All"
                          to="/media"
                          isMenu={false}
                          mainMenuOpened={menuOpened}
                          setCurrentMenu={setCurrentMenu}
                          closeMenu={closeMenu}
                        />,
                        <MenuItem
                          index={2}
                          name="Livestreaming"
                          to="/media/livestreaming"
                          isMenu={false}
                          mainMenuOpened={menuOpened}
                          setCurrentMenu={setCurrentMenu}
                          closeMenu={closeMenu}
                        />,
                        <MenuItem
                          index={3}
                          name="Music"
                          to="/media/music"
                          isMenu={false}
                          mainMenuOpened={menuOpened}
                          setCurrentMenu={setCurrentMenu}
                          closeMenu={closeMenu}
                        />,
                        <MenuItem
                          index={4}
                          name="Production Work"
                          to="/media/production-work"
                          isMenu={false}
                          mainMenuOpened={menuOpened}
                          setCurrentMenu={setCurrentMenu}
                          closeMenu={closeMenu}
                        />,
                        <MenuItem
                          index={5}
                          name="Videos"
                          to="/media/videos"
                          isMenu={false}
                          mainMenuOpened={menuOpened}
                          setCurrentMenu={setCurrentMenu}
                          closeMenu={closeMenu}
                        />,
                      ]}
                    />
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

function CloseMenuItem({ mainMenuOpened, closeMenu }) {
  return (
    <Transition.Child
      show={mainMenuOpened}
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0 translate-x-[-10vw]"
      enterTo="opacity-100 translate-x-[0]"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 translate-x-[0]"
      leaveTo="opacity-0 translate-x-[-10vw]"
    >
      <button
        aria-label="Close Menu"
        onClick={() => closeMenu()}
        className="flex items-center space-x-2 group hover:text-primary text-5xl transition-all duration-300 ease-out"
      >
        <XMark className="group-hover:fill-primary h-14 w-14 transition-colors duration-300 ease-out" />
      </button>
    </Transition.Child>
  );
}

function MenuItem({
  ref,
  index,
  name,
  isURL,
  to,
  isMenu,
  mainMenuOpened,
  setCurrentMenu,
  closeMenu,
}) {
  const delay = `delay-${(index * 100).toString()}`;

  console.log(delay);

  const combineClassNames = require("classnames");

  return (
    <Transition.Child
      show={mainMenuOpened}
      as={Fragment}
      enter={combineClassNames("ease-out duration-300", delay)}
      enterFrom="opacity-0 translate-x-[-10vw]"
      enterTo="opacity-100 translate-x-[0]"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 translate-x-[0]"
      leaveTo="opacity-0 translate-x-[-10vw]"
    >
      {isMenu ? (
        <button
          aria-label={name.toString()}
          ref={ref}
          onClick={() => setCurrentMenu(to)}
          className="hover:text-primary relative hover:fill-primary flex items-center text-5xl transition-all ease-out"
        >
          {name.toString()}
          <ChevronRight className=" absolute -right-8 transition-all ease-out h-7 w-7" />
        </button>
      ) : isURL ? (
        <a
          ref={ref}
          onClick={() => closeMenu()}
          href={to}
          rel="noreferrer noopener"
          className="hover:text-primary text-5xl transition-all ease-out"
        >
          {name.toString()}
        </a>
      ) : (
        <Link
          ref={ref}
          onClick={() => closeMenu()}
          to={to}
          className="hover:text-primary text-5xl transition-all ease-out"
        >
          {name.toString()}
        </Link>
      )}
    </Transition.Child>
  );
}

function MenuPage({ ref, mainMenuOpened, menuItems, setCurrentMenu }) {
  return (
    <div className="h-full w-full flex flex-col justify-center space-y-4 items-center transition-all duration-300 ease-out">
      <Transition.Child
        show={mainMenuOpened}
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-x-[-10vw]"
        enterTo="opacity-100 translate-x-[0]"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-x-[0]"
        leaveTo="opacity-0 translate-x-[-10vw]"
      >
        <button
          aria-label="Go Back"
          ref={ref}
          onClick={() => setCurrentMenu("main")}
          className=" hover:fill-primary text-5xl transition-all duration-300 ease-out"
        >
          <ArrowLeft className="h-14 w-14 transition-colors duration-300 ease-out" />
        </button>
      </Transition.Child>
      {menuItems}
    </div>
  );
}

function DarkModeButton({ index, menuOpened }) {
  const combineClassNames = require("classnames");

  const delay = `delay-${(index * 100).toString()}`;

  const [lightMode, action] = useDarkMode(false, {
    classNameDark: "light",
    classNameLight: "dark",
    storageKey: "lightMode",
  });

  return (
    <Transition.Child
      show={menuOpened}
      as={Fragment}
      enter={combineClassNames("ease-out duration-300", delay)}
      enterFrom="opacity-0 translate-x-[-10vw]"
      enterTo="opacity-100 translate-x-[0]"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 translate-x-[0]"
      leaveTo="opacity-0 translate-x-[-10vw]"
    >
      <button
        className="hover:fill-primary transition-all ease-out"
        title="Switch Themes"
        aria-label="Switch Themes"
        onClick={action.toggle}
      >
        {lightMode ? (
          <Sun className="animate-fadeIn transition-all duration-300 ease-out h-14 w-14" />
        ) : (
          <Moon className="animate-fadeIn transition-all duration-300 ease-out h-14 w-14" />
        )}
      </button>
    </Transition.Child>
  );
}

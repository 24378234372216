import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useRef } from "react";

import ChevronDown from "../../../media/svg/chevron-down.svg";
import { Link } from "gatsby";

export default function DropDownMenu({
  title,
  to,
  isExternalLink,
  childrenItems,
}) {
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  // Determines how long menu stays open for after user stops hovering over dropdown
  const timeoutDuration = 100;
  let timeout;

  const openMenu = () => {
    buttonRef?.current.click();
  };

  const closeMenu = () => {
    dropdownRef?.current?.dispatchEvent(
      new KeyboardEvent("keydown", {
        key: "Tab",
        bubbles: true,
        cancelable: true,
      })
    );
    document.activeElement.blur();
  };

  const onMouseEnter = (closed) => {
    clearTimeout(timeout);
    closed && openMenu();
  };
  const onMouseLeave = (open) => {
    open && (timeout = setTimeout(() => closeMenu(), timeoutDuration));
  };

  const combineClassNames = require("classnames");

  return (
    <Menu as="div" className="relative inline-block text-left menu-object">
      {({ open }) => (
        <>
          <div
            aria-hidden="true"
            onClick={openMenu}
            onMouseEnter={() => onMouseEnter(!open)}
            onMouseLeave={() => onMouseLeave(open)}
          >
            {isExternalLink ?? false ? (
              <a
                className={combineClassNames(
                  "hover:text-primary transition-colors ease-out",
                  open ? "text-primary " : ""
                )}
                href={to}
                rel="noreferrer noopener"
              >
                {title}
              </a>
            ) : (
              <Link
                className={combineClassNames(
                  "hover:text-primary transition-colors ease-out",
                  open ? "text-primary " : ""
                )}
                to={to}
              >
                {title}
              </Link>
            )}
            <Menu.Button
              ref={buttonRef}
              className={combineClassNames(
                "inline-block align-baseline transition-colors ease-out cursor-pointer ml-1 hover:outline-none",
                open ? "text-primary" : ""
              )}
            >
              <ChevronDown
                className={combineClassNames(
                  "inline-block align-baseline hover:fill-primary transition ease-out leading-none h-3 w-3 hover:outline-none",
                  open ? "fill-primary rotate-180" : ""
                )}
              />
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              ref={dropdownRef}
              onMouseEnter={() => onMouseEnter()}
              onMouseLeave={() => onMouseLeave(open)}
              static
              className="menu-items origin-top-left absolute z-20 left-0 mt-2 w-56 rounded-md shadow-lg bg-slate-100 dark:bg-slate-700 outline-none hover:outline-none"
            >
              <div className="rounded-md text-slate-900 dark:text-slate-50">
                {childrenItems.map((child) => (
                  <Menu.Item key={child.path}>
                    {({ active }) =>
                      isExternalLink ?? false ? (
                        <a
                          className={combineClassNames(
                            "block px-4 py-3 text-sm transition-colors hover:bg-slate-900 dark:hover:bg-slate-100 rounded-md hover:text-slate-100 dark:hover:text-slate-900 ease-out",
                            active
                              ? "bg-slate-900 text-slate-100 dark:bg-slate-100 dark:text-slate-900"
                              : ""
                          )}
                          href={child.path}
                          rel="noreferrer noopener"
                        >
                          {child.name}
                        </a>
                      ) : (
                        <Link
                          to={child.path}
                          className={combineClassNames(
                            "block px-4 py-3 text-sm transition-colors hover:bg-slate-900 dark:hover:bg-slate-100 rounded-md hover:text-slate-100 dark:hover:text-slate-900 ease-out",
                            active
                              ? "bg-slate-900 text-slate-100 dark:bg-slate-100 dark:text-slate-900"
                              : ""
                          )}
                        >
                          {child.name}
                        </Link>
                      )
                    }
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}
